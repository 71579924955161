import React from "react";

interface ErrorProps {
  error: string | undefined;
}

export const Error = ({ error }: ErrorProps) => {
  return (
    <React.Fragment>
      {error && (
        <div className="m-2">
          <div className="w-full p-2 bg-red-50 text-red-600 text-semibold">
            {error}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
