import { FiArrowLeft, FiEdit, FiSend, FiTrash } from "react-icons/fi";
import { useIsAuth } from "../../../hooks/useIsAuth";
import Layout from "../../system/components/Layout";
import { DefaultButton } from "../../system/components/DefaultButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/reducer";
import { Error } from "../../system/components/Error";
import { eventActions } from "../reducer";
import { ConfirmationModal } from "../../system/components/ConfirmationModal";
import React, { useEffect, useState } from "react";
import { SecondaryButton } from "../../system/components/SecondaryButton";
import { DangerButton } from "../../system/components/DangerButton";
import toasts from "../../../utils/toast";

export const RegistrationsToSend = () => {
  useIsAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const registrations = useSelector(
    (state: RootState) => state.event?.registrations_to_send ?? []
  );
  const events = useSelector((state: RootState) => state.event?.events ?? []);
  const isLoadingSendRegistrations = useSelector(
    (state: RootState) => state.event?.isLoadingSendRegistrations ?? false
  );
  const isLoadingSendRegistrationKey = useSelector(
    (state: RootState) => state.event?.isLoadingSendRegistrationKey ?? -1
  );
  const networkOnline = useSelector(
    (state: RootState) => state.system.networkOnline ?? -1
  );

  const sendRegistration = (
    registration: Record<string, string | number>,
    index: number
  ) => {
    if (isLoadingSendRegistrations) {
      toasts.warning(
        `Já está sendo enviado todos os cadastros, aguarde finalizar.`
      );
      return;
    }

    if (isLoadingSendRegistrationKey !== -1) {
      toasts.warning(`Já tem um cadastro sendo enviado, aguarde finalizar.`);
      return;
    }

    if (!networkOnline) {
      toasts.warning(
        `Só é possível enviar cadastros quando estiver com internet.`
      );
      return;
    }

    dispatch(
      eventActions.sendRegistration({
        registration,
        index,
      })
    );
  };

  const sendRegistrations = () => {
    if (isLoadingSendRegistrations) {
      toasts.warning(
        `Já está sendo enviado todos os cadastros, aguarde finalizar.`
      );
      return;
    }

    if (isLoadingSendRegistrationKey !== -1) {
      toasts.warning(`Já está sendo enviado um cadastro, aguarde finalizar.`);
      return;
    }

    if (!networkOnline) {
      toasts.warning(
        `Só é possível enviar cadastros quando estiver com internet.`
      );
      return;
    }

    if (registrations.length === 0) {
      toasts.warning(`Nenhum cadastro disponível para enviar.`);
      return;
    }

    dispatch(eventActions.sendRegistrations(registrations));
  };

  const removeRegistration = (key: number) => {
    dispatch(eventActions.removeRegistration(key));
    setItemBeingDeleted(-1);
  };

  const getEvent = (registration: Record<string, string | number>) => {
    return events.find((i) => i.ukey == (registration as any)["evento_ukey"]);
  };

  const error = useSelector((state: RootState) => state.event?.error ?? null);

  const [itemBeingDeleted, setItemBeingDeleted] = useState(-1);

  useEffect(() => {
    dispatch(eventActions.setNoLoadingSendRegistration());
  }, []);

  return (
    <Layout
      title={
        <span className="w-full flex justify-center align-center font-bold text-2xl text-white my-auto gap-2">
          <span className="my-auto">Cadastros sem enviar</span>
        </span>
      }
      onSearch={() => {}}
    >
      <div className="px-3">
        <DefaultButton
          size="sm"
          color="gray"
          externalClass="w-auto flex flex-wrap gap-1 items-center mx-auto"
          onClick={() => navigate(`/form/`)}
        >
          <FiArrowLeft /> Voltar
        </DefaultButton>

        <DefaultButton
          color="green"
          externalClass="w-auto flex flex-wrap gap-1 items-center mx-auto my-4"
          onClick={() => sendRegistrations()}
          processing={isLoadingSendRegistrations}
        >
          <FiSend /> Enviar Todos ({registrations.length})
        </DefaultButton>

        {error && <Error error={error} />}

        <ConfirmationModal
          show={itemBeingDeleted !== -1}
          close={() => setItemBeingDeleted(-1)}
          title={"Remover Cadastro"}
          content={"Você tem certeza que deseja remover este cadastro?"}
          footer={
            <React.Fragment>
              <SecondaryButton
                externalClass="w-auto"
                onClick={() => setItemBeingDeleted(-1)}
              >
                Cancelar
              </SecondaryButton>

              <DangerButton
                externalClass="ms-3"
                onClick={() => removeRegistration(itemBeingDeleted)}
              >
                Remover
              </DangerButton>
            </React.Fragment>
          }
        />

        <div className="w-full flex flex-wrap">
          {registrations.map((registration, index) => (
            <div
              key={index}
              className="w-full border rounded-md shadow flex flex-col bg-white p-3 mb-3"
            >
              <span>
                <b> Evento: </b>
                {getEvent(registration)?.nome}
              </span>

              {Object.keys(registration)
                .filter((i) => {
                  const campo = (getEvent(registration)?.campos ?? []).find(
                    (j) => j.name == i
                  );

                  if (campo?.type == "decimal" || !campo) {
                    if (registration[i] == 0) {
                      return false;
                    }
                  }

                  return i != "evento_ukey";
                })
                .map((key) => (
                  <span key={key}>
                    <b>
                      {" "}
                      {
                        (getEvent(registration)?.campos ?? []).find(
                          (j) => j.name == key
                        )?.label
                      }
                      :{" "}
                    </b>
                    {registration[key]}
                  </span>
                ))}

              <div className="w-full flex flex-wrap mt-2">
                <div className="px-1 w-1/3">
                  <DefaultButton
                    color="red"
                    size="sm"
                    externalClass="w-full flex flex-wrap gap-1 items-center justify-center"
                    processing={
                      index === isLoadingSendRegistrationKey ||
                      isLoadingSendRegistrations
                    }
                    onClick={() => {
                      if (
                        index !== isLoadingSendRegistrationKey &&
                        !isLoadingSendRegistrations
                      ) {
                        setItemBeingDeleted(index);
                      }
                    }}
                  >
                    <FiTrash /> Excluir
                  </DefaultButton>
                </div>
                <div className="px-1 w-1/3">
                  <DefaultButton
                    color="blue"
                    size="sm"
                    externalClass="w-full flex flex-wrap gap-1 items-center justify-center"
                    onClick={() =>
                      navigate(
                        `/registrations-to-send/${
                          getEvent(registration)?.ukey ?? ""
                        }/${index}`
                      )
                    }
                    processing={
                      index === isLoadingSendRegistrationKey ||
                      isLoadingSendRegistrations
                    }
                  >
                    <FiEdit /> Editar
                  </DefaultButton>
                </div>
                <div className="px-1 w-1/3">
                  <DefaultButton
                    color="green"
                    size="sm"
                    externalClass="w-full flex flex-wrap gap-1 items-center justify-center"
                    onClick={() => sendRegistration(registration, index)}
                    processing={
                      index === isLoadingSendRegistrationKey ||
                      isLoadingSendRegistrations
                    }
                  >
                    <FiSend /> Enviar
                  </DefaultButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};
