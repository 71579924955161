import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { store } from "../modules/app/reducer";
import getEnv from "../utils/getEnv";
import { CANCEL } from "redux-saga";

export const APIURL = getEnv("REACT_APP_API_HOST");

export const getToken = () => store.getState().system.auth_token;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status == 401) {
      window.location.href = "/logout";
      return;
    }
    return Promise.reject(error);
  }
);

export const apiCall = (config: AxiosRequestConfig) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const request = axios({
    ...config,
    baseURL: APIURL,
    cancelToken: source.token,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${getToken()}`,
    },
  });

  (request as any)[CANCEL] = () => source.cancel();
  return request;
};
