import { useEffect } from "react";
import { useIsAuth } from "../../../hooks/useIsAuth";
import { useDispatch } from "react-redux";
import { systemActions } from "../reducer";

export const Logout = () => {
  useIsAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(systemActions.logout());
  }, []);

  return <></>;
};
