import { all } from "redux-saga/effects";
import { systemSaga } from "../system/saga";
import { eventSaga } from "../event/saga";

export function* mainSaga() {
  yield all([
    systemSaga(),
    eventSaga(),
  ]);
}
