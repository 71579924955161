import React, { useEffect, useState } from "react";
import { FiDownload, FiPlus } from "react-icons/fi";
import { DefaultButton } from "./DefaultButton";
import { ListHeader } from "./ListHeader";
import { DialogModal } from "./DialogModal";
import { usePWAInstall } from "react-use-pwa-install";

export const InstallButton = () => {
  const install = usePWAInstall();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleBeforeInstallPrompt = (event: any) => {
    // Prevent Chrome 76 and later from showing the mini-infobar
    event.preventDefault();
  };

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  if (iOS()) {
    return (
      <React.Fragment>
        <DialogModal
          show={isOpen}
          close={() => setIsOpen(false)}
          title={<ListHeader size="lg">Instale o aplicativo</ListHeader>}
          content={
            <div className="w-full flex flex-wrap">
              <div className="flex items-center gap-2 mb-4">
                <img
                  style={{ width: "30px" }}
                  src="https://upload.wikimedia.org/wikipedia/commons/c/c8/Ei-share-apple.svg"
                />
                1) No SAFARI, pressione o botão 'Compartilhar'
              </div>
              <div className="flex items-center gap-2">
                <FiPlus fontSize={"30px"} />
                Clique em 'Adicionar à Tela de início'
              </div>
            </div>
          }
          footer={
            <div className="w-full">
              <DefaultButton color="gray" onClick={() => setIsOpen(false)}>
                Voltar
              </DefaultButton>
            </div>
          }
        />

        <DefaultButton onClick={() => setIsOpen(true)} externalClass="w-auto">
          <FiDownload size={"1.2rem"} />
        </DefaultButton>
      </React.Fragment>
    );
  }

  return (
    <DefaultButton onClick={() => install && install()} externalClass="w-auto">
      <FiDownload size={"1.2rem"} />
    </DefaultButton>
  );
};
