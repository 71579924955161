import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ILoginForm } from "./saga";

type InitialState = {
  networkOnline: boolean;
  auth_token: string | null;
  error?: string;
};

const initialState: InitialState = {
  networkOnline: true,
  auth_token: "",
};

const reducer = createSlice({
  name: "system",
  initialState,
  reducers: {
    loginRequest(state, _: PayloadAction<ILoginForm>) {
      state.error = "";
    },
    loginSuccess(state, { payload }: PayloadAction<string>) {
      state.auth_token = payload;
    },
    loginError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
    },
    logout(state) {
      state.auth_token = null;
      state.error = "";
    },
    setNetworkStatus(state, { payload }: PayloadAction<boolean>) {
      state.networkOnline = payload;
    },
  },
});

export const systemActions = reducer.actions;

export default reducer.reducer;
