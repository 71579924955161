import { useIsAuth } from "../../../hooks/useIsAuth";
import Layout from "../../system/components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "../reducer";
import { RootState } from "../../app/reducer";
import { useEffect, useState } from "react";
import { Error } from "../../system/components/Error";
import { SelectInput } from "../../system/components/SelectInput";
import { useNavigate, useParams } from "react-router-dom";
import { DefaultButton } from "../../system/components/DefaultButton";
import { FiClipboard } from "react-icons/fi";
import toasts from "../../../utils/toast";

export const Events = () => {
  useIsAuth();

  const isLoading = useSelector((state: RootState) => state.event.isLoading);
  const events = useSelector((state: RootState) => state.event.events);
  const registrationsToSend = useSelector(
    (state: RootState) => state.event.registrations_to_send ?? []
  );
  const error = useSelector((state: RootState) => state.event.error);
  const networkOnline = useSelector(
    (state: RootState) => state.system.networkOnline
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const search = () => {
    if (!networkOnline) {
      toasts.warning(`Só é possível buscar os dados com internet!`);
      return;
    }

    dispatch(eventActions.eventsRequest());
  };

  const { event_ukey } = useParams();
  const [selectedEvent, setSelectedEvent] = useState(event_ukey ?? "");

  useEffect(() => {
    navigate(`/form/${selectedEvent}`);
  }, [selectedEvent]);

  useEffect(() => {
    if (networkOnline) {
      search();
    }
    if (!event_ukey && (events ?? []).length === 1) {
      setSelectedEvent((events ?? [])[0].ukey);
    }
  }, []);

  useEffect(() => {
    if (!isLoading && networkOnline) {
      if (!event_ukey && (events ?? []).length === 1) {
        setSelectedEvent((events ?? [])[0].ukey);
      }
    }
  }, [isLoading]);

  return (
    <Layout
      showInstallButton={true}
      onSearch={search}
      isLoading={isLoading}
      title={
        <DefaultButton
          externalClass="w-auto font-xs flex flex-wrap gap-1 items-center"
          size="sm"
          onClick={() => navigate("/registrations-to-send")}
        >
          Cadastros sem enviar ({registrationsToSend.length})
        </DefaultButton>
      }
    >
      <div className="px-3">
        <span className="w-full flex justify-center align-center font-bold text-3xl text-gray-600 my-auto gap-2">
          <span className="my-auto mb-3">Captura de Dados</span>
        </span>

        <Error error={error} />
        <SelectInput
          label="Evento"
          externalClass="w-full"
          value={selectedEvent}
          onChange={(evt) => setSelectedEvent(evt.target.value)}
        >
          <option value="">Selecione o evento...</option>
          {(events ?? []).map((event) => (
            <option value={event.ukey} key={event.ukey}>
              {event.nome}
            </option>
          ))}
        </SelectInput>

        {isLoading && (events ?? []).length === 0 && (
          <div className="w-full flex justify-center mx-auto mt-3">
            {" "}
            Carregando...{" "}
          </div>
        )}

        {event_ukey && (events ?? []).find((e) => e.ukey == event_ukey) && (
          <div className="w-full flex flex-wrap mt-8">
            <div className="w-full">
              <DefaultButton
                onClick={() => navigate(`/form/${event_ukey}/registration`)}
                externalClass="w-full font-semibold flex flex-wrap items-center gap-2 justify-center"
              >
                <FiClipboard /> Novo cadastro
              </DefaultButton>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
