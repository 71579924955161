import React, { ReactNode } from "react";
import { FiRefreshCw, FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/reducer";
import { systemActions } from "../reducer";
import { FiLoader } from "react-icons/fi";
import { DefaultButton } from "./DefaultButton";
import "../css/Layout.css";
import toasts from "../../../utils/toast";
import { InstallButton } from "./InstallButton";

interface IProps {
  children: ReactNode;
  onSearch?: () => void;
  isLoading?: boolean;
  title?: ReactNode;
  showInstallButton?: boolean;
}

const Layout = ({ children, onSearch, isLoading = false, title, showInstallButton = false }: IProps) => {
  const networkOnline = useSelector(
    (state: RootState) => state.system.networkOnline
  );

  const dispatch = useDispatch();
  const logout = () => {
    if (!networkOnline) {
        toasts.error("Só é possível sair do aplicativo quando está com internet.");
        return;
    }
    if (! window.confirm('Confirma deslogar do aplicativo?')) {
        return;
    }
    dispatch(systemActions.logout());
  };

  return (
    <div className="bg-gray-100 flex flex-col w-full relative min-h-[100vh]">
      <div className="flex flex-col bg-blue-600 fixed z-10 w-full">
        <div className="min-w-[100vw] sm:min-w-[500px] p-2 mx-auto">
          <div className="flex justify-between">
            <DefaultButton externalClass="w-auto" onClick={logout}>
              <FiLogOut size={"1.2rem"} />
            </DefaultButton>
            {showInstallButton && <InstallButton />}
            {title}
            <div className="flex">
              {typeof onSearch === "function" && (
                <React.Fragment>
                  {isLoading ? (
                    <FiLoader
                      className="rotating text-white my-auto mr-2"
                      size="2rem"
                    />
                  ) : (
                    <DefaultButton externalClass="w-auto" onClick={onSearch}>
                      <FiRefreshCw />
                    </DefaultButton>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        {networkOnline && (
          <div className="flex flex-col bg-green-100 text-green-800 z-10 w-full">
            <div className="min-w-[100vw] sm:min-w-[500px] mx-auto text-center font-semibold font-xs">
              <small>Internet Online</small>
            </div>
          </div>
        )}
        {!networkOnline && (
          <div className="flex flex-col bg-red-100 text-red-800 z-10 w-full">
            <div className="min-w-[100vw] sm:min-w-[500px] mx-auto text-center font-semibold font-xs">
              <small>Internet Offline</small>
            </div>
          </div>
        )}
      </div>

      <main className="px-0 pt-24 pb-24 w-full flex flex-wrap justify-center">
        <div className="min-w-[100vw] sm:min-w-[500px] sm:max-w-[500px]">
          {children}
        </div>
      </main>
    </div>
  );
};

export default Layout;
