import { useDispatch, useSelector } from "react-redux";
import Layout from "../../system/components/Layout";
import { eventActions } from "../reducer";
import { RootState } from "../../app/reducer";
import { useNavigate, useParams } from "react-router-dom";
import { useIsAuth } from "../../../hooks/useIsAuth";
import { DefaultButton } from "../../system/components/DefaultButton";
import { FiArrowLeft } from "react-icons/fi";
import { Error } from "../../system/components/Error";
import { TextInput } from "../../system/components/TextInput";
import React, { ChangeEvent, useEffect, useState } from "react";
import { SelectInput } from "../../system/components/SelectInput";
import { MaskInput } from "../../system/components/MaskInput";
import toasts from "../../../utils/toast";
import { DecimalInput } from "../../system/components/DecimalInput";

export const Registration = () => {
  useIsAuth();
  const dispatch = useDispatch();

  const { event_ukey, index } = useParams();

  const networkOnline = useSelector(
    (state: RootState) => state.system.networkOnline
  );

  const search = () => {
    if (!networkOnline) {
      toasts.warning(`Só é possível buscar os dados com internet!`);
      return;
    }

    dispatch(eventActions.eventsRequest());
  };
  const isLoading = useSelector((state: RootState) => state.event.isLoading);
  const newRegistrationSuccess = useSelector(
    (state: RootState) => state.event.newRegistrationSuccess
  );
  const editingRegistrationSuccess = useSelector(
    (state: RootState) => state.event.editingRegistrationSuccess
  );

  const event = useSelector((state: RootState) =>
    (state.event?.events ?? []).find((i) => i.ukey === event_ukey)
  );

  const registrations = useSelector(
    (state: RootState) => state.event?.registrations_to_send ?? []
  );

  const getRegistration = () => {
    if (index === undefined) {
        return {};
    }

    return {
        ...registrations[parseInt(index)],
        index,
    };
  }

  const isEditing = index !== undefined;

  const [form, setForm] = useState<Record<string, string | number>>({
    evento_ukey: event_ukey as string,
    volume_compra_mes_em_kg: 0,
    ...getRegistration(),
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | number,
    key: string
  ) => {
    if (typeof e === "number") {
      setForm((form) => ({
        ...form,
        [key]: e,
      }));
      return;
    }

    const value = e.target.value;

    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = () => {
    console.log("handleSubmit", form);

    const isDirty = Object.keys(form).filter((keyForm) => {
        const campo = (event?.campos ?? []).find((campo) => campo.name == keyForm);

        if (keyForm == 'evento_ukey') {
            return false;
        }

        if (campo?.type == 'decimal' || ! campo) {
            if ((form[keyForm] as number) == 0) {
                return false;
            }
        }

        return true;
    }).length > 0

    if (! isDirty) {
      toasts.error(`Preencha ao menos 1 campo para enviar o formulário.`);
      setIsSaving(false);
      return;
    }

    if (isEditing)  {
        dispatch(eventActions.storeEditingRegistration(form));
        return;
    }

    dispatch(eventActions.storeNewRegistration(form));
  };

  function debounceSave(func: any, wait: any) {
    let timeout: any;
    return function executedFunction(...args: any) {
      setIsSaving(true);
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const addOrderWithDebounce = debounceSave(handleSubmit, 500);

  useEffect(() => {
    dispatch(eventActions.setNewRegistrationSuccess(false));
  }, []);

  useEffect(() => {
    if (newRegistrationSuccess) {
      dispatch(eventActions.setNewRegistrationSuccess(false));
      navigate(`/form/${event_ukey}`);
    }
  }, [newRegistrationSuccess]);

  useEffect(() => {
    if (editingRegistrationSuccess) {
      dispatch(eventActions.setEditingRegistrationSuccess(false));
      navigate(`/registrations-to-send`);
    }
  }, [editingRegistrationSuccess]);

  return (
    <Layout
      onSearch={search}
      isLoading={isLoading}
      title={
        <span className="w-full flex justify-center align-center font-bold text-2xl text-white my-auto gap-2">
          <span className="my-auto">Formulário</span>
        </span>
      }
    >
      <div className="px-3">
        <DefaultButton
          size="sm"
          color="gray"
          externalClass="w-auto flex flex-wrap gap-1 items-center mx-auto"
          onClick={() => navigate(isEditing ? '/registrations-to-send' : `/form/${event_ukey}`)}
        >
          <FiArrowLeft /> Voltar sem salvar
        </DefaultButton>

        {!event && (
          <Error error="Erro ao buscar evento, volte na tela inicial e faça novamente" />
        )}

        {event && (
          <React.Fragment>
            <div className="w-full">
              {event.campos.map((field, index) => (
                <React.Fragment key={`${index}`}>
                  {(field?.items ?? []).length > 0 &&
                    !field.mascara &&
                    field.type != "decimal" && (
                      <SelectInput
                        label={field.label}
                        onChange={(event) => handleChange(event, field.name)}
                        externalClass="w-full mt-2"
                        value={form[field.name] ?? ''}
                      >
                        <option value={""}>Selecione...</option>
                        {field.items?.map((item, index2) => (
                          <option key={`${index2}`} value={item.value}>{item.label}</option>
                        ))}
                      </SelectInput>
                    )}

                  {(field?.items ?? []).length === 0 &&
                    !field.mascara &&
                    field.type != "decimal" && (
                      <TextInput
                        label={field.label}
                        value={form[field.name] ?? ''}
                        onChange={(event) => handleChange(event, field.name)}
                        maxLength={field.maxLength}
                        type={field.type}
                        externalClass="w-full mt-2"
                      />
                    )}

                  {(field?.items ?? []).length === 0 &&
                    field.mascara &&
                    field.type != "decimal" && (
                      <MaskInput
                        label={field.label}
                        onChange={(event) => handleChange(event, field.name)}
                        mask={field.mascara}
                        value={form[field.name] ?? ""}
                        externalClass="w-full mt-2"
                      />
                    )}

                  {field.type == "decimal" && (
                    <DecimalInput
                      label={field.label}
                      value={form[field.name] ?? ""}
                      onChangeVal={(event) => handleChange(event, field.name)}
                      externalClass="w-full mt-2"
                      decimalPlaces={0}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>

            <DefaultButton
              size="sm"
              color="gray"
              externalClass="w-auto flex flex-wrap gap-1 items-center mx-auto mt-6"
              onClick={() => navigate(isEditing ? '/registrations-to-send' : `/form/${event_ukey}`)}
            >
              <FiArrowLeft /> Voltar sem salvar
            </DefaultButton>

            <div className="h-20"></div>

            <div className="w-full flex bg-white shadow-[15px_10px_10px_5px] w-full p-2 justify-center fixed bottom-0 left-0">
              <div className="min-w-[100vw] sm:min-w-[500px] px-3">
                <DefaultButton
                  processing={isSaving}
                  onClick={addOrderWithDebounce}
                  externalClass="w-full"
                >
                  Salvar formulário
                </DefaultButton>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </Layout>
  );
};
