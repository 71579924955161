import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { systemActions } from "../reducer";
import { ILoginForm } from "../saga";
import { Logo } from "../components/Logo";
import { RootState } from "../../app/reducer";
import { TextInput } from "../components/TextInput";
import { DefaultButton } from "../components/DefaultButton";
import { useNavigate } from "react-router-dom";

function Login() {
  const [form, setForm] = useState<ILoginForm>({
    user: "",
    password: "",
  });

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const key = e.target.id;
    const value = e.target.value;

    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  }

  const [processing, setProcessing] = useState(false);

  const error = useSelector((state: RootState) => state.system.error);
  const auth_token = useSelector((state: RootState) => state.system.auth_token);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setProcessing(true);

    dispatch(systemActions.loginRequest(form));
  }

  useEffect(() => {
    if (!!error) {
      setProcessing(false);
    }
  }, [error]);

  useEffect(() => {
    if (!!auth_token) {
      navigate("/form");
    }
  }, [auth_token]);

  return (
    <div className="bg-gray-50 min-h-[100vh] flex flex-col justify-center align-center">
      <div className="w-full md:w-[25vw] mx-auto">
        <span className="w-full flex justify-center align-center font-bold text-3xl text-gray-600 my-auto gap-2">
          <span className="my-auto mb-3">Captura de Dados</span>
        </span>
        <div className="bg-white rounded p-10 rounded-xl shadow-lg">
          <div className="w-full flex justify-center">
            <Logo />
          </div>
          <div className="flex justify-between items-center">
            <h3 className="text-3xl font-semibold	text-gray-800">Entrar</h3>
          </div>
          <div className="relative">
            <h2 className="text-md font-normal text-gray-700 mt-2 mb-8">
              Insira suas credenciais abaixo:
            </h2>
            {error && (
              <div className="my-4 text-sm font-medium text-red-600 bg-red-50 p-2 rounded-md">
                {error}
              </div>
            )}
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="mb-4">
                <TextInput
                  id="user"
                  type="user"
                  label="Usuário"
                  value={form.user}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <TextInput
                  id="password"
                  type="password"
                  label="Senha"
                  value={form.password}
                  onChange={handleChange}
                />
              </div>
              <DefaultButton processing={processing}>Entrar</DefaultButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
