import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { systemActions } from "./reducer";
import helper from "../../utils/helper";
import { APIURL } from "../../config/api";

export interface ILoginForm {
  user: string;
  password: string;
}

function loginCall(payload: ILoginForm) {
  return axios.post(`${APIURL}/tokens/create`, {
    usuario: payload.user,
    device_name: "MktEventoCapturaDados",
    password: payload.password,
    cia_ukey: "1",
  });
}

function* loginWorker({ payload }: PayloadAction<ILoginForm>) {
  try {
    const res: AxiosResponse<{ token: string }> = yield call(
      loginCall,
      payload
    );
    yield put(systemActions.loginSuccess(res.data.token));
  } catch (error) {
    yield put(systemActions.loginError(helper.formatError(error)));
  }
}

export function* systemSaga() {
  yield all([takeLatest("system/loginRequest", loginWorker)]);
}
