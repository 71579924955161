import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Event } from "./types/event";
import toasts from "../../utils/toast";

type InitialState = {
  error?: string;
  isLoading?: boolean;
  events?: Event[];
  registrations_to_send?: Record<string, string | number>[];
  newRegistrationSuccess?: boolean;
  editingRegistrationSuccess?: boolean;
  isLoadingSendRegistrationKey?: number;
  isLoadingSendRegistrations?: boolean;
};

const initialState: InitialState = {};

const reducer = createSlice({
  name: "event",
  initialState,
  reducers: {
    error(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isLoading = false;
      state.isLoadingSendRegistrations = false;
      state.isLoadingSendRegistrationKey = -1;
    },
    eventsRequest(state) {
      state.error = "";
      state.isLoading = true;
    },
    eventsRequestSuccess(state, { payload }: PayloadAction<Event[]>) {
      state.isLoading = false;
      state.events = payload;
    },
    setNewRegistrationSuccess(state, { payload }: PayloadAction<boolean>) {
      state.newRegistrationSuccess = payload;
    },
    setEditingRegistrationSuccess(state, { payload }: PayloadAction<boolean>) {
      state.editingRegistrationSuccess = payload;
    },
    storeNewRegistration(
      state,
      { payload }: PayloadAction<Record<string, string | number>>
    ) {
      state.error = "";
      state.newRegistrationSuccess = false;

      const currentRegistrationsToSend = JSON.parse(
        JSON.stringify(state?.registrations_to_send ?? [])
      );
      currentRegistrationsToSend.push(payload);
      state.registrations_to_send = currentRegistrationsToSend;

      state.newRegistrationSuccess = true;
    },
    storeEditingRegistration(
      state,
      { payload }: PayloadAction<Record<string, string | number>>
    ) {
      state.error = "";
      state.editingRegistrationSuccess = false;

      const currentRegistrationsToSend = JSON.parse(
        JSON.stringify(state?.registrations_to_send ?? [])
      );
      const currentIndex = payload.index;
      delete payload.index;
      currentRegistrationsToSend[currentIndex] = payload;
      state.registrations_to_send = currentRegistrationsToSend;

      state.editingRegistrationSuccess = true;
    },
    setNoLoadingSendRegistration(state) {
      state.isLoadingSendRegistrationKey = -1;
      state.isLoadingSendRegistrations = false;
    },
    removeRegistration(state, { payload }: PayloadAction<number>) {
      state.error = "";
      state.newRegistrationSuccess = false;

      const currentRegistrationsToSend = JSON.parse(
        JSON.stringify(state?.registrations_to_send ?? [])
      );
      currentRegistrationsToSend.splice(payload, 1);
      state.registrations_to_send = currentRegistrationsToSend;
    },
    sendRegistration(state, { payload }: PayloadAction<{ registration: Record<string, string | number>, index: number; }>) {
      state.error = "";
      state.isLoadingSendRegistrationKey = payload.index;
    },
    sendRegistrationSuccess(state, { payload }: PayloadAction<{ registration: Record<string, string | number>, index: number; }>) {
      state.error = "";
      const currentRegistrationsToSend = JSON.parse(
        JSON.stringify(state?.registrations_to_send ?? [])
      );
      currentRegistrationsToSend.splice(payload.index, 1);
      state.registrations_to_send = currentRegistrationsToSend;
      state.isLoadingSendRegistrationKey = -1;
      toasts.success('Cadastro enviado com sucesso.');
    },
    sendRegistrations(state, _: PayloadAction<Record<string, string | number>[]>) {
      state.error = "";
      state.isLoadingSendRegistrations = true;
    },
    sendRegistrationsSuccess(state, _: PayloadAction<Record<string, string | number>[]>) {
      state.error = "";
      toasts.success(`${(state.registrations_to_send ?? [])?.length} cadastros enviados com sucesso.`);
      state.registrations_to_send = [];
      state.isLoadingSendRegistrations = false;
    },
  },
});

export const eventActions = reducer.actions;

export default reducer.reducer;
