import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import helper from "../../utils/helper";
import { eventActions } from "./reducer";
import { apiCall } from "../../config/api";
import { Event } from "./types/event";
import { ApiListResponse } from "../../types/api";
import { PayloadAction } from "@reduxjs/toolkit";
import toasts from "../../utils/toast";

function* eventsWorker() {
  try {
    const res: AxiosResponse<ApiListResponse<Event[]>> = yield call(apiCall, {
      url: "/eventos?q=finalizado_em|isnull|;&p=100&o=nome",
      method: "get",
    });
    yield put(eventActions.eventsRequestSuccess(res.data.data));
  } catch (error) {
    toasts.error(helper.formatError(error));
    yield put(eventActions.error(helper.formatError(error)));
  }
}

function* sendRegistrationWorker({
  payload,
}: PayloadAction<{ registration: Record<string, string | number>; index: number }>) {
  try {
    const res: AxiosResponse<{ success: boolean }> = yield call(apiCall, {
      url: "/marketing/envia-cadastro-evento",
      method: "post",
      data: {
        cadastros: [
          {
            ...payload.registration,
          },
        ],
      },
    });
    if (!res.data.success) {
      toasts.error(`Erro desconhecido ao enviar.`);
      return;
    }
    yield put(eventActions.sendRegistrationSuccess(payload));
  } catch (error) {
    toasts.error(helper.formatError(error));
    yield put(eventActions.error(helper.formatError(error)));
  }
}

function* sendRegistrationsWorker({
  payload,
}: PayloadAction<Record<string, string | number>[]>) {
  try {
    const res: AxiosResponse<{ success: boolean }> = yield call(apiCall, {
      url: "/marketing/envia-cadastro-evento",
      method: "post",
      data: {
        cadastros: payload,
      },
    });
    if (!res.data.success) {
      toasts.error(`Erro desconhecido ao enviar.`);
      return;
    }
    yield put(eventActions.sendRegistrationsSuccess(payload));
  } catch (error) {
    toasts.error(helper.formatError(error));
    yield put(eventActions.error(helper.formatError(error)));
  }
}

export function* eventSaga() {
  yield all([takeLatest("event/eventsRequest", eventsWorker)]);
  yield all([takeLatest("event/sendRegistration", sendRegistrationWorker)]);
  yield all([takeLatest("event/sendRegistrations", sendRegistrationsWorker)]);
}
