const helper = {
  formatError: (error: any) => {
    if (error?.response?.data?.error) {
      return error?.response?.data?.error;
    }

    return error?.response?.data?.message
      ? error.response?.data?.message
      : JSON.stringify(error);
  },
  formatNumber(val: number | string, decimalPlaces = 2) {
    let returnValue = '0';
    if (typeof val === "number") {
      returnValue = val
        .toFixed(decimalPlaces)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
      returnValue = parseFloat(val)
        .toFixed(decimalPlaces)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return returnValue;
  },
};

export default helper;
