import { Routes, Route } from "react-router-dom";
import { useChangeNetworkStatus } from "../../hooks/useChangeNetworkStatus";
import { Events } from "../event/views/Events";
import Login from "../system/views/Login";
import { Logout } from "../system/views/Logout";
import { Registration } from "../event/views/Registration";
import { RegistrationsToSend } from "../event/views/RegistrationsToSend";
import { Home } from "../system/views/Home";

export const Router: React.FC<{}> = () => {
  useChangeNetworkStatus();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/form" element={<Events />} />
      <Route path="/form/:event_ukey" element={<Events />} />
      <Route path="/form/:event_ukey/registration" element={<Registration />} />
      <Route path="/form/:event_ukey/registration" element={<Registration />} />
      <Route path="/registrations-to-send" element={<RegistrationsToSend />} />
      <Route path="/registrations-to-send/:event_ukey/:index" element={<Registration />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
};
